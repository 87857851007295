<template>
  <div class="acn-public-jobs">
    <AvailableJobsTable
      :tableColumns="tableColumns"
      :tableData="tableData"
      :tablePagination="tablePagination"
      :isLoading.sync="isLoading"
      @onPageChange="onPageChange"
      @onPerPageChange="onPerPageChange"
      @click="toPublicJobPage"
    ></AvailableJobsTable>
  </div>
</template>

<script>
import usePublicJobsTable from "@/modules/ACN/composables/usePublicJobsTable";

export default {
  setup(props, context) {
    const {
      isLoading,
      tableColumns,
      tableData,
      tablePagination,
      tableParams,
      updateParams,
      onPageChange,
      onPerPageChange,
      getAvailableJobs
    } = usePublicJobsTable("normal", { context });

    return {
      isLoading,
      tableColumns,
      tableData,
      tablePagination,
      tableParams,
      updateParams,
      onPageChange,
      onPerPageChange,
      getAvailableJobs
    };
  },
  components: {
    AvailableJobsTable: () =>
      import("@/modules/ACN/components/AvailableJobsTable")
  },
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.getAvailableJobs();
  },
  methods: {
    toPublicJobPage(jobId) {
      this.$router.push({ name: "ACNPublicJobDetail", params: { id: jobId } });
    }
  }
};
</script>

<style lang="scss"></style>
