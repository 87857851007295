import { ref, unref } from "@vue/composition-api";
import { acn as acnAPI } from "@/api";
import { cleanQueryParam } from "@/utils/filter";
import { cloneDeep } from "lodash";

export default function usePublicJobsTable(type, { context }) {
    const tableColumns = ref([
        {
            label: "Job",
            field: (row) =>
                type == "specialist" ? "Specialist" : row.acnJobScope.title
        },
        {
            label: "Property",
            field: "property"
        }
    ]);

    const tableData = ref([]);

    const tablePagination = ref({
        total: 0
    });

    const tableParams = ref({
        page: 1,
        perPage: 30
    });

    const isLoading = ref(false);

    const filter = ref({});

    // ============================ Table Functions ============================
    function updateParams(newProps) {
        tableParams.value = Object.assign({}, tableParams.value, newProps);
    }

    function onPageChange(params) {
        updateParams({ page: params.currentPage });
        getAvailableJobs();
    }

    function onPerPageChange(params) {
        updateParams({ perPage: params.currentPerPage });
        getAvailableJobs();
    }

    async function getAvailableJobs() {
        try {
            isLoading.value = true;
            let filteredParam = cleanQueryParam(unref(filter));

            let res = await acnAPI.getAvailableJobs(type, {
                queries: filteredParam,
                page: unref(tableParams.page),
                perPage: unref(tableParams.perPage)
            });

            tableData.value = cloneDeep(res.data);
            tablePagination.value = cloneDeep(res.meta.pagination);

            isLoading.value = false;
        } catch (error) {
            isLoading.value = false;
            context.root.$notify({
                group: "alert",
                type: "error",
                title: "Error",
                text: "Failed to fetch jobs. Please try again later."
            });
        }
    }

    return {
        isLoading,
        tableColumns,
        tableData,
        tablePagination,
        tableParams,
        updateParams,
        onPageChange,
        onPerPageChange,
        getAvailableJobs
    };
}
